/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Outlet } from "react-router-dom";
import Header from "./layouts/Header.jsx";
import Sidebar from "./layouts/Sidebar.jsx";
import { useContext } from "react";
import { Context } from "./context/Context.js";
import { useLocation } from "react-router-dom";

const Dashboard = () => {
  const [open, setOpen] = useState(true);
  const context = useContext(Context);
  const location = useLocation();
  const pathname = location?.pathname;
  const dashboardConfig = context.dashboardConfig;
  const navigate = useNavigate();
  const [activeTab, setActiveTab] = useState("home");
  const [activeDropdown, setActiveDropdown] = useState(null);

  const handleDropdownToggle = (menuItem) => {
    setActiveDropdown(
      activeDropdown === menuItem.menuLabel ? null : menuItem.menuLabel
    );
  };

  const handleMenuToggle = () => {
    setOpen(!open);
    setActiveDropdown(null);
  };
  const handleTabChange = (tab) => {
    setActiveTab(tab);
    // setActiveDropdown(null);
  };
  useEffect(() => {
    const fetchConfig = async () => {
      try {
        const response = await fetch("/config.dashboard.json");
        const dashboardConfigData = await response.json();
        context?.onSetDashboardConfig(dashboardConfigData);
      } catch (error) {
        console.error("Error fetching dashboard config:", error);
      }
    };

    fetchConfig();
  }, []);

  useEffect(() => {
    const user = JSON.parse(localStorage.getItem("lyttl-user"));
    if (!user?.email || !user?.password) {
      navigate("/login");
      return;
    }

    const defaultDashboard = dashboardConfig?.defaultDashboard?.path;

    const navigationRoute =
      pathname === "/dashboard" ? defaultDashboard : pathname;
    navigate(navigationRoute);
  }, [dashboardConfig]);

  return (
    <div className="flex w-full">
      {/* Sidebar navigation */}
      <Sidebar
        activeDropdown={activeDropdown}
        activeTab={activeTab}
        handleTabChange={handleTabChange}
        handleMenuToggle={handleMenuToggle}
        handleDropdownToggle={handleDropdownToggle}
        open={open}
      />
      {/* Mian content */}
      <Header setActiveTab={setActiveTab} handleMenuToggle={handleMenuToggle} />
      <div className=" flex-1 mx-2 mt-[56px] px-1">
        <Outlet />
      </div>
    </div >
  );
};

export default Dashboard;
